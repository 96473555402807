import { makeMainGEvent, pushEvent } from '@src/utils/analytics';

export const sendOctoberPrizeDrawGoToVkVideoEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: 'Розыгрыш|Переход на страницу результатов',
            eventLabel: 'Акция OZON октябрь 2024',
        }),
    );
};

export const sendOctoberPrizeDrawShowAllHistoriesEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: 'Розыгрыш|Смотреть истории',
            eventLabel: 'Акция OZON октябрь 2024',
        }),
    );
};

export const sendOctoberPrizeDrawShowAnswerEvent = (history: string) => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: `Розыгрыш|Популярные вопросы|${history}`,
            eventLabel: 'Акция OZON октябрь 2024',
        }),
    );
};

export const sendOctoberPrizeDrawSupportEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: 'Розыгрыш|Остались вопросы',
            eventLabel: 'Акция OZON октябрь 2024',
        }),
    );
};

export const sendOctoberPrizeDrawFullTermEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: 'Розыгрыш|Полные условия акции',
            eventLabel: 'Акция OZON октябрь 2024',
        }),
    );
};

export const sendOctoberPrizeDrawGetLoanEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: 'Розыгрыш|Получить займ и стать участником',
            eventLabel: 'Акция OZON октябрь 2024',
        }),
    );
};

export const sendCheckPhoneNumberClickEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: 'Микрокредиты',
            eventAction: 'Розыгрыш|Проверить результаты',
            eventLabel: 'Акция OZON октябрь 2024',
            eventValue: undefined,
        }),
    );
};
