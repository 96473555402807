import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import { CommonHead } from '@sravni/next-common-head';
import { Banner, BANNER_TYPES } from '@sravni/react-advertisement';
import { Logo } from '@sravni/react-design-system';
import { useIsPhone, useTheme } from '@sravni/react-utils';

import { AppHeader } from '@src/components/AppHeader';
import { MobileAppBanner } from '@src/components/MobileAppBanner';
import { NoScript } from '@src/components/NoScript';
import SeoHead from '@src/components/Seo/SeoHead';
import { SpecialPageDisclaimer } from '@src/components/SpecialPageDisclaimer';
import { CloseButton } from '@src/components/Toast';
import { BANNER_TYPE_TOP } from '@src/constants/banners';
import { PRELOAD_FONTS } from '@src/constants/preloadFonts';
import { getGtmOptions } from '@src/helpers/getGtmOptions';
import { useSelectBanners } from '@src/reducers/banners';
import { useHasUtmLabel } from '@src/reducers/route';
import { useSelectUser } from '@src/reducers/user';
import {
    isAppLandingPage,
    isFullDealLandingPage,
    isMfoAndroidPage,
    isNewSpecialPage,
    isPartner2GisPage,
    isPartnersPage,
    isPromoTermsPage,
    isPromotionPageWithoutQuery,
    isSpecialPageWithoutQuery,
    isWhiteLabelPage,
} from '@src/utils/routing';
import { Footer } from 'widgets/Footer';

import { useFetchRegions, useInitSentryAndMindbox, useSetHistory } from '../lib';

import styles from './Layout.module.scss';

interface Props {
    mode?: 'landing' | 'default';
    footer?: ReactNode;
    header?: ReactNode;

    showMobileAppBanner?: boolean;
}

/**
 * todo(MFO-1275): Продолжить рефакторинг и удалить все зависимости от страниц. Вся логика должна решаться на уровне страниц,
 * а не в общем компоненте Layout.
 */
export const Layout: FC<Props> = ({
    children,
    mode = 'default',
    footer = <Footer mode={mode} />,
    header = <AppHeader mode={mode} />,
    showMobileAppBanner = true,
}) => {
    const [theme] = useTheme();
    const isPhone = useIsPhone();

    const user = useSelectUser();
    const banners = useSelectBanners();
    const isContextPage = useHasUtmLabel();

    const { asPath } = useRouter();

    // todo(MFO-1275): Распределить хуки по фичам
    useFetchRegions();
    useInitSentryAndMindbox();
    useSetHistory();

    const isWhiteLabel = isWhiteLabelPage(asPath);
    const isPartnerPage = isPartnersPage(asPath);
    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isPromotion = isPromotionPageWithoutQuery(asPath);
    const isPromoTerms = isPromoTermsPage(asPath);
    const isAppLanding = isAppLandingPage(asPath);
    const isMfoAndroidLanding = isMfoAndroidPage(asPath);
    const isNewSpecial = isNewSpecialPage(asPath);
    const isFullDealLanding = isFullDealLandingPage(asPath);
    const isPartner2Gis = isPartner2GisPage(asPath);

    const analyticsOptions = getGtmOptions({
        userId: user?.sub,
        isWhiteLabel,
        asPath,
        theme,
    });

    const isHeaderAndFooterShowed =
        !isWhiteLabel &&
        !isPartnerPage &&
        !isNewSpecial &&
        !isSpecial &&
        !isPromoTerms &&
        !isAppLanding &&
        !isMfoAndroidLanding &&
        !isPartner2Gis;

    const isPageOnlyForMobile = isFullDealLanding;
    const _showMobileAppBanner = !isContextPage && showMobileAppBanner;

    return (
        <>
            <CommonHead
                analyticsOptions={analyticsOptions}
                // @ts-ignore
                designSystem="custom" // хак, чтобы не подтягивались все дефолтные шрифты, зашитые в CommonHead
                preloadedFonts={PRELOAD_FONTS}
            />
            <NoScript isWhiteLabel={isWhiteLabel} />
            <SeoHead />
            <ToastContainer hideProgressBar closeButton={<CloseButton />} limit={1} />
            <div className={cn({ [styles.containerForMobile]: isPageOnlyForMobile })}>
                <div
                    className={cn(styles.container, {
                        slimContent: isPromotion,
                    })}
                >
                    <Banner abName="" banner={banners?.[BANNER_TYPE_TOP]} type={BANNER_TYPES.TOP} />

                    {_showMobileAppBanner && <MobileAppBanner />}

                    {isHeaderAndFooterShowed && header}

                    {(isSpecial || isNewSpecial || isPartner2Gis || (isPromotion && !isPhone)) && (
                        <div
                            className={cn(styles.specialLogoContainer, {
                                [styles.headerMargin]: isPromotion,
                            })}
                        >
                            <div className={styles.specialLogo}>
                                <Logo />
                            </div>
                        </div>
                    )}
                    {children}
                </div>

                {(isSpecial || isPartner2Gis) && <SpecialPageDisclaimer />}

                {isHeaderAndFooterShowed && footer}
            </div>
        </>
    );
};
