import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchRegions } from '@src/reducers/locations';

export const useFetchRegions = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);
};
