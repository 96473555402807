export const BANNER_LIST_POSITION_INDEX = 12;
export const BANNER_TYPE = 'Tgbm7';
export const BANNER_TYPE_FIXED = 'OutOfPage';
export const BANNER_TYPE_TOP = 'top';

export const CATEGORY = 'banks';
export const PRODUCT = 'microCredit';

export const PAGE = {
    CALCULATOR: 'calculator',
    LISTING: 'listing',
};
