const vitrinsWithMindboxVisitEvent = [
    '/zaimy/',
    '/zaimy/onlain/',
    '/zaimy/na-kartu/',
    '/zaimy/na-kartu-bez-otkazov/',
    '/zaimy/s-plokhoj-kreditnoj-istoriej/',
    '/zaimy/bez-protsentov/',
    '/zaimy/top/',
    '/zaimy/pod-pts/',
    '/zaimy/cherez-gosuslugi/',
    '/zaimy/na-kartu-do-zarplaty/',
    '/zaimy/dolgosrochnye/',
    '/zaimy/s-prosrochkami/',
];

export const VITRINS_WITH_MINDBOX_VISIT_EVENT = new Set(vitrinsWithMindboxVisitEvent);
