import has from 'lodash/has';
import { useRouter } from 'next/router';
import React from 'react';

import { isSpecialPageWithoutQuery } from '@src/utils/routing';

import styles from './styles.module.scss';

export const SpecialPageDisclaimer = () => {
    const { asPath, query } = useRouter();
    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isCRMSpecial = isSpecial && has(query, 'landing_type');

    return (
        <div className={styles.container}>
            {!isCRMSpecial && <p>* По мнению Сравни</p>}
            <br />
            <p>
                Сервис Sravni.ru бесплатно предоставляет информацию о займах. Процентные ставки и срок погашения займа
                могут отличаться, т. к. зависят от выбранной вами микрофинансовой организации (МФО). Ставки по займам
                могут составлять от 0% до 0,8% в день (максимальный процент по займу составит 292% годовых). Срок у
                данных займов может быть от 1 дня до 365 дней. Это означает, что минимальный срок погашения займа: 1
                день. Максимальный срок погашения займа: 365 дней.
            </p>
            <p>
                Информация о займах не является офертой или публичной офертой: конечные условия для выдачи конкретного
                займа определяются на сайте конкретного МФО в индивидуальном порядке.
            </p>
            <p>
                Наши основные партнеры среди МФО: ООО МФК «Вэббанкир» (Рег. №&nbsp;2120177002077), ООО МФК «Мани Мен»
                (Рег. №&nbsp;2110177000478), ООО МФК «Займер» (Рег. №&nbsp;651303532004088), ООО МКК «КапиталЪ-НТ» (Рег.
                №&nbsp;001603465007766), ООО МКК «Русинтерфинанс» (Рег. №&nbsp;2120754001243).
            </p>
        </div>
    );
};
