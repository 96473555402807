import { makeMainGEvent, pushEvent } from '@src/utils/analytics';

import { EVENT_CATEGORY } from './constants';

export const sendCloseMobileAppBannerEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY,
            eventAction: 'Баннер|Закрыть',
            eventLabel: 'Баннер|Скачать приложение',
        }),
    );
};

export const sendDownloadMobileAppBannerEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY,
            eventAction: 'Баннер|Клик',
            eventLabel: 'Баннер|Скачать приложение',
        }),
    );
};

export const sendShowMobileAppBannerEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY,
            eventAction: 'Баннер|Показ',
            eventLabel: 'Баннер|Скачать приложение',
        }),
    );
};
