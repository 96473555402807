/* удаляет начальный и конечный слеш урла,
тк в mindbox события настроены на такой формат
/zaimy/cherez-gosuslugi/ -> zaimy/cherez-gosuslugi
*/

export const getFormattedPathForMindboxEvent = (path: string): string => path.replace(/^\/|\/$/g, '');

export const sendVisitVitrinEvent = (path: string) => {
    const formattedPath = getFormattedPathForMindboxEvent(path);
    if (window.mindbox) {
        window.mindbox('async', {
            operation: 'VisitUrl',
            data: {
                viewProduct: {
                    product: {
                        ids: {
                            website: formattedPath,
                        },
                    },
                },
            },
        });
    }
};
