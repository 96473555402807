import type { Currency } from '@src/@types/currency';
import type { Breadcrumb, FaqItem, Offer } from '@src/components/Seo/types';
import { ListKeys } from '@src/constants/microcredits';

export const MAX_RATING_VALUE = 5;
export const MIN_RATING_VALUE = 4.7;

export const MAX_REVIEW_COUNT_VALUE = 10000;
export const MIN_REVIEW_COUNT_VALUE = 1000;

export const BEST_RATING_VALUE = '5';

export const WORST_RATING_VALUE = '1';

export const MIN_AMOUNT = '100';
export const BASE_URL = 'https://www.sravni.ru';

export const OFFER_TEMPLATE = {
    '@type': 'LoanOrCredit',
    name: 'Микрозайм «{MFO.name}»',
    brand: '{MFO.organization.name}',
    loanTerm: {
        '@type': 'QuantitativeValue',
        name: 'Срок займа',
        minValue: '{MFO.termRange.fromValue}',
        maxValue: '{MFO.termRange.toValue}',
        unitCode: 'DAY',
    },
    annualPercentageRate: {
        '@type': 'QuantitativeValue',
        name: 'Процентная ставка в день',
        minValue: '{MFO.rateRange.from}',
    },
    amount: {
        '@type': 'MonetaryAmount',
        name: 'Сумма займа',
        minValue: '{MFO.amountRange.from}',
        maxValue: '{MFO.amountRange.to}',
        currency: 'RUB',
    },
};

export const PRODUCT_TEMPLATE = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: ' {main.title} ',
    description: '{page.description}',
    offers: {
        '@type': 'AggregateOffer',
        lowPrice: '{min.amount}',
        highPrice: '{max.amount}',
        priceCurrency: 'RUB' as Currency,
        offerCount: '{MFO.count}',
        offers: [] as Offer[],
    },
};

export const ORGANIZATION_TEMPLATE = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: '{main.title}',
    breadcrumb: {
        '@type': 'BreadcrumbList',
        numberOfItems: 2,
        itemListElement: [] as Breadcrumb[],
    },
    author: {
        '@type': 'Organization',
        name: 'Сравни.ру',
        url: 'https://www.sravni.ru/',
        logo: 'https://www.sravni.ru/f/_/newdesign/img/logo2.svg',
    },
};

export const FAQ_TEMPLATE = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [] as FaqItem[],
};

export const FAQ_ITEM_TEMPLATE = {
    '@type': 'Question',
    name: '{faq.question}',
    acceptedAnswer: {
        '@type': 'Answer',
        text: '{faq.answer}',
    },
};

export const DEFAULT_BREADCRUMB = {
    '@type': 'ListItem',
    position: 1,
    name: 'Сравни.ру',
    item: BASE_URL,
};

export const LISTS_KEYS = [
    ListKeys.LIST,
    ListKeys.ONLINE,
    ListKeys.NOVINKI,
    ListKeys.CALCULATOR,
    ListKeys.LANDING,
    ListKeys.BRANDING,
    ListKeys.LEADSSU,
    ListKeys.GURULEADS,
    ListKeys.ZAIMY_POD_PTS,
    ListKeys.KAZAKHSTAN,
];
