export enum CurrencySymbol {
    RUB = '\u20BD',
    USD = '\u0024',
    EUR = '\u20AC',
    GBP = '\u00A3',
    JPY = '\u00A5',
    CHF = '\u20A3',
    CNY = '\u04B0',
    UAH = '\u20B4',
    HKD = '\u0024',
    KZT = '\u20B8',
}
