import { makeMainGEvent, pushEvent } from '@src/utils/analytics';

import { EVENT_CATEGORY } from './constants';

export const sendOpenCardDetailsInModalEvent = (eventLabel: string) => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY,
            eventAction: 'Модальное окно с информацией о займе',
            eventLabel,
        }),
    );
};
