import React from 'react';

import { GTM_ID } from '@src/constants/analytics';

interface IProps {
    isWhiteLabel: boolean;
}

export const NoScript = ({ isWhiteLabel }: IProps) => {
    const gtmId = isWhiteLabel ? GTM_ID.WL : GTM_ID.DEFAULT;

    return (
        <noscript>
            <iframe
                src={`//www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
            />
        </noscript>
    );
};
