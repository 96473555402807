import React, { memo } from 'react';

import type { FooterProps } from '@sravni/react-footer';
import { Footer as CommonFooter, SupportChannelType } from '@sravni/react-footer';

import { useSelectFooterSettings } from '@src/reducers/siteSettings';

import styles from './Footer.module.scss';

export const Footer = memo(({ mode = 'default' }: Pick<FooterProps, 'mode'>) => {
    const { support, menu, socialLinks } = useSelectFooterSettings() || {};

    // Todo(MFO-1276): Переехать на redux toolkit и переместить это в селектор
    const footerSupport = {
        ...support,
        channels: [
            {
                type: SupportChannelType.TELEGRAM,
                link: 'https://t.me/SravniMFOSupport_bot',
            },
            {
                type: SupportChannelType.PHONE,
                link: '88007073902',
            },
            {
                type: SupportChannelType.EMAIL,
                link: 'mfo_support@sravni.ru',
            },
            {
                type: SupportChannelType.WHATSAPP,
                link: 'https://wa.me/79252943371',
            },
        ],
    };

    return (
        <CommonFooter
            className={styles.footer}
            mode={mode}
            support={footerSupport}
            menuLinks={menu}
            socialLinks={socialLinks}
        />
    );
});

Footer.displayName = 'Footer';
