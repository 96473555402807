import { makeMainGEvent, pushEvent } from '@src/utils/analytics';

import { EVENT_CATEGORY_FULL_DEAL } from './constants';

export const sendClickOnLoanAmountEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY_FULL_DEAL,
            eventAction: 'mfoAmountConfirm',
            eventLabel: 'state - Сумма займа|screen - mfoFullDealLanding',
        }),
    );
};

export const sendClickOnLoanTermEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY_FULL_DEAL,
            eventAction: 'mfoTermConfirm',
            eventLabel: 'state - Срок займа|screen - mfoFullDealLanding',
        }),
    );
};

export const sendClickOnSubmitLoanAmountEvent = (eventValue: number) => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY_FULL_DEAL,
            eventAction: 'mfoAmountConfirm',
            eventLabel: 'state - Готово|screen - mfoFullDealLanding, Сумма займа',
            eventValue,
        }),
    );
};

export const sendClickOnSubmitLoanTermEvent = (eventValue: number) => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY_FULL_DEAL,
            eventAction: 'mfoTermConfirm',
            eventLabel: 'state - Готово|screen - mfoFullDealLanding, Срок займа',
            eventValue,
        }),
    );
};

export const sendClickOnSubmitLoanEvent = (buttonName: string) => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY_FULL_DEAL,
            eventAction: 'mfoNextButton',
            eventLabel: `state - ${buttonName}|screen - mfoFullDealLanding`,
        }),
    );
};

export const sendScrollFullDealStepsEvent = () => {
    pushEvent(
        makeMainGEvent({
            eventCategory: EVENT_CATEGORY_FULL_DEAL,
            eventAction: 'mfoNextButton',
            eventLabel: 'state - Свайп|screen - mfoFullDealLanding',
        }),
    );
};
