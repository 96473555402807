import type * as Microcredits from '@src/@types/microcredits';

const BASE_URL = 'https://www.sravni.ru';

const loanOrCreditTemplate = {
    '@context': 'http://schema.org',
    '@type': 'LoanOrCredit',
    url: '{url}',
    name: 'Микрозайм «{name}»',
    brand: '{organization.name}',
    loanTerm: {
        '@type': 'QuantitativeValue',
        name: 'Срок займа',
        minValue: '{min.term}',
        maxValue: '{max.term}',
        unitCode: 'DAY',
    },
    annualPercentageRate: {
        '@type': 'QuantitativeValue',
        name: 'Процентная ставка в день',
        minValue: '{range}',
    },
    amount: {
        '@type': 'MonetaryAmount',
        name: 'Сумма займа',
        minValue: '{min.amount}',
        maxValue: '{max.amount}',
        currency: 'RUB',
    },
};

const webPageTemplate = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Микрозайм «{name}»',
    breadcrumb: {
        '@type': 'BreadcrumbList',
        numberOfItems: 3,
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Сравни.ру',
                item: 'https://www.sravni.ru',
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: 'Микрозаймы',
                item: 'https://www.sravni.ru/zaimy/',
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: '{organization.name}',
                item: 'https://www.sravni.ru/zaimy/mfo/{organization.alias}/zaim/{alias}/',
            },
        ],
    },
};

export const generateJsonLdForItemPage = (item: Microcredits.Card.IRes): string | undefined => {
    if (!item) return undefined;

    const mfoUrl = `${BASE_URL}/zaimy/mfo/${item.organization.alias}/zaim/${item.alias}/`;
    loanOrCreditTemplate.url = mfoUrl;
    webPageTemplate.breadcrumb.itemListElement[2].item = mfoUrl;
    const template = [loanOrCreditTemplate, webPageTemplate];

    return JSON.stringify(template)
        .replace(/{name}/g, item.name)
        .replace(/{organization.name}/g, item.organization.name)
        .replace('{min.term}', item.periods.from.toString())
        .replace('{max.term}', item.periods.to.toString())
        .replace(
            '{range}',
            item.rates.RUB[0].periods[0].rate.from ? item.rates.RUB[0].periods[0].rate.from.toString() : '',
        )
        .replace('{min.amount}', item.amount.from.toString())
        .replace('{max.amount}', item.amount.to.toString());
};

export const getTitle = (item: Microcredits.Card.IRes) =>
    `Микрокредит ${item.name} от МФО ${item.organization.name} со ставкой ${item.rates.RUB[0].periods[0].rate.from} на сумму от ${item.amount.from} до ${item.amount.to} рублей`;

export const getDescription = (item: Microcredits.Card.IRes) =>
    `Оформить микрокредит ${item.name} от МФО ${item.organization.name} на срок от ${item.periods.from} до ${item.periods.to} дней. Мгновенно получить от ${item.amount.from} до ${item.amount.to} рублей под ${item.rates.RUB[0].periods[0].rate.from}% в день с переводом на карту или электронный кошелек.`;
