import { API_PATH } from '@src/config/routes';

import client from '../utils/client';

export const loadIndexingRegions = async (url: string) => {
    const { data } = await client.get(`${API_PATH}/metadata/index`, {
        params: { url },
    });

    return data;
};
