import type { FC } from 'react';
import { memo } from 'react';

interface ICloseButtonProps {
    className?: string;
    closeToast?: () => void;
}

export const CloseButton: FC<ICloseButtonProps> = memo(({ className, closeToast }) => (
    <svg
        onClick={closeToast}
        className={`Toastify__close-button Toastify__close-button--default ${className || ''}`}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12.5722 3L3 12.5722" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5722 12.5722L3 3" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
));
