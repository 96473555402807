import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';

import { isProduction } from './isProduction';
import { isServer } from './isServer';

interface IEvent {
    event: 'adEvent' | 'mainEvent' | 'pageViewGA' | string;
    title?: string;
    path?: string;
    eventCategory?: string | null;
    eventAction?: string | null;
    eventLabel?: string | null;
    eventValue?: number;

    'gtm.start'?: number;
}

interface IEventSource {
    event: IEvent['event'];
    eventValue?: IEvent['eventValue'];
    title?: IEvent['title'];
    path?: IEvent['path'];
    eventCategory?: string | string[];
    eventAction?: string | string[];
    eventLabel?: string | string[] | null;
}

interface IAnalyticsWindow extends Window {
    sravniDataLayer: IEvent[];
    yaCounter159737?: {
        getClientID: () => string;
    };
    yaCounter87750877?: {
        getClientID: () => string;
    };
}

declare const window: IAnalyticsWindow;

type TCookies = {
    get: (key: string) => any;
};

const isCookieClass = (cookies: TCookies | Record<string, any>): cookies is TCookies =>
    typeof cookies.get === 'function';

export const getYandexMetricsIdJS = (isWl: boolean) => {
    if (typeof window !== 'undefined') {
        if (isWl) {
            return window.yaCounter87750877 ? window.yaCounter87750877.getClientID() : '';
        }

        return window.yaCounter159737 ? window.yaCounter159737.getClientID() : '';
    }

    return '';
};

export const getYandexMetricsId = (cookies: TCookies | Record<string, any>, isWl: boolean) => {
    try {
        const yandexId = isCookieClass(cookies) ? cookies.get('_ym_uid') : cookies._ym_uid;

        return yandexId || getYandexMetricsIdJS(isWl);
    } catch (_e) {
        return getYandexMetricsIdJS(isWl);
    }
};

export const getGaIdFromCookies = (_ga: string): string => {
    try {
        const [, , part1, part2] = _ga.split('.');

        return `${part1}.${part2}`;
    } catch (_e) {
        return '0000000000.0000000000';
    }
};

const stringifyEventValue = (strings: string | string[] | null | undefined): string | undefined | null =>
    Array.isArray(strings) ? strings.join('|') : strings;

// Send data to Data Layer
const pushGoogleEvent = (event: IEvent) => {
    if (!isServer) {
        if (isProduction) {
            // setup initial datalayer before add new event
            window?.sravniDataLayer?.push(event);
        } else {
            console.log('pushEvent', event);
        }
    }
};

// Push events
export const pushEvent = (event: IEventSource) => {
    pushGoogleEvent({
        ...event,
        eventAction: stringifyEventValue(event.eventAction),
        eventLabel: stringifyEventValue(event.eventLabel),
        eventCategory: stringifyEventValue(event.eventCategory),
    });
};

export const makeMainGEvent = (event: Partial<IEventSource>): IEventSource => ({
    eventLabel: undefined,
    eventAction: undefined,
    eventValue: undefined,
    eventCategory: undefined,
    ...event,
    event: 'mainEvent',
});

export const makeAdGEvent = (event: Partial<IEventSource>): IEventSource => ({
    eventLabel: undefined,
    eventAction: undefined,
    eventValue: undefined,
    eventCategory: undefined,
    ...event,
    event: 'adEvent',
});

export const itemLabelFactory = (item: IAnalyticsItem) =>
    `${item.organization.name}|${item.name}|${item.organization._id}|${item._id}`;
