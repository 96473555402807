import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setHistory, useSelectHistoryUrls } from '@src/reducers/history';

export const useSetHistory = () => {
    const dispatch = useDispatch();

    const urls = useSelectHistoryUrls();
    const { asPath } = useRouter();

    useEffect(() => {
        if (urls[urls.length - 1] !== asPath) {
            dispatch(setHistory([asPath]));
        }
    }, [dispatch, asPath, urls]);
};
